const WEBSOCKET_URL = 'wss://hometask.eg1236.com/game1/';

const NEW_GAME_REQUEST = 'NEW_GAME_REQUEST';
const NEW_GAME_SUCCESS = 'NEW_GAME_SUCCESS';
const NEW_GAME_FAILURE = 'NEW_GAME_FAILURE';

const MAP_REQUEST = 'MAP_REQUEST';
const MAP_SUCCESS = 'MAP_SUCCESS';
const MAP_FAILURE = 'MAP_FAILURE';

const MAP_MESSAGE_REQUEST = 'MAP_MESSAGE_REQUEST';
const MAP_MESSAGE_SUCCESS = 'MAP_MESSAGE_SUCCESS';
const MAP_MESSAGE_FAILURE = 'MAP_MESSAGE_FAILURE';

const OPEN_CELL_REQUEST = 'OPEN_CELL_REQUEST';
const OPEN_CELL_SUCCESS = 'OPEN_CELL_SUCCESS';
const OPEN_CELL_FAILURE = 'OPEN_CELL_FAILURE';

const TOGGLE_FLAG_REQUEST = 'TOGGLE_FLAG_REQUEST';
const TOGGLE_FLAG_SUCCESS = 'TOGGLE_FLAG_SUCCESS';
const TOGGLE_FLAG_FAILURE = 'TOGGLE_FLAG_FAILURE';

const FLAG_REQUEST = 'FLAG_REQUEST';
const FLAG_SUCCESS = 'FLAG_SUCCESS';
const FLAG_FAILURE = 'FLAG_FAILURE';

const RESET_FLAGGED_REQUEST = 'RESET_FLAGGED_REQUEST';
const RESET_FLAGGED_SUCCESS = 'RESET_FLAGGED_SUCCESS';
const RESET_FLAGGED_FAILURE = 'RESET_FLAGGED_FAILURE';

const FIND_BOMBS_REQUEST = 'FIND_BOMBS_REQUEST';
const FIND_BOMBS_SUCCESS = 'FIND_BOMBS_SUCCESS';
const FIND_BOMBS_FAILURE = 'FIND_BOMBS_FAILURE';

const SET_GAME_STATE = 'SET_GAME_STATE';

const SET_LEVEL = 'SET_LEVEL';

const SKIP_NEXT_MAP_UPDATE = 'SKIP_NEXT_MAP_UPDATE';

const SOLVER_NEEDS_ASSISTANCE = 'SOLVER_NEEDS_ASSISTANCE';

export {
  WEBSOCKET_URL,

  TOGGLE_FLAG_REQUEST,
  TOGGLE_FLAG_SUCCESS,
  TOGGLE_FLAG_FAILURE,

  FLAG_REQUEST,
  FLAG_SUCCESS,
  FLAG_FAILURE,

  RESET_FLAGGED_REQUEST,
  RESET_FLAGGED_SUCCESS,
  RESET_FLAGGED_FAILURE,

  NEW_GAME_REQUEST,
  NEW_GAME_SUCCESS,
  NEW_GAME_FAILURE,

  MAP_REQUEST,
  MAP_SUCCESS,
  MAP_FAILURE,

  MAP_MESSAGE_REQUEST,
  MAP_MESSAGE_SUCCESS,
  MAP_MESSAGE_FAILURE,

  OPEN_CELL_REQUEST,
  OPEN_CELL_SUCCESS,
  OPEN_CELL_FAILURE,

  FIND_BOMBS_REQUEST,
  FIND_BOMBS_SUCCESS,
  FIND_BOMBS_FAILURE,

  SET_GAME_STATE,

  SET_LEVEL,

  SKIP_NEXT_MAP_UPDATE,

  SOLVER_NEEDS_ASSISTANCE,
};